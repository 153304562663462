/*App.css*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.app-container {
  height: 100vh;
}

.sidebar-container {
  height: 100%;
  overflow-y: auto;
}

.dashboard-container {
  height: 100%;
  overflow-y: auto;
}

.card.h-100 {
  height: 100%;
}

.earnings-chart {
  height: 200px;
  background-color: #eef;
  margin-top: 20px;
}

.form-control {
  margin-top: 10px;
  padding: 10px;
}

button {
  margin-top: 10px;
  padding: 10px;
  width: auto; /* Change this from 100% if it was set */
  box-sizing: border-box;
}

.alert {
  display: none; /* Hide the alert initially */
}

.d-flex.flex-column.h-100 {
  display: flex;
  flex-direction: column;
  height: 100%;
}

h2 {
  margin-bottom: 0;
}

h4.text-muted {
  margin-top: 0;
  font-size: 1.25rem;
}

.d-flex.justify-content-between.mt-4 .card {
  margin-bottom: 0 !important;
}

.card.p-3.mx-2 {
  margin-bottom: 0;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.card.p-3.mx-2 h3 {
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.card.p-3.mx-2 h2 {
  font-size: 2rem;
  color: #007bff;
}

.btn-lg {
  font-size: 1.25rem;
  padding: 10px 20px;
}

.min-vh-100 {
  min-height: 100vh;
}

.d-flex.align-items-center.justify-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.btn-blue {
  background-color: #007bff;
  border: none;
  color: white;
  width: 100%; /* Ensure full width */
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.btn-blue:hover {
  background-color: #0056b3;
}


.auth-form {
  max-width: 400px;
  margin: auto;
}

.text-right {
  text-align: right;
}

.no-underline {
  text-decoration: none;
  color: #007bff;
}

.no-underline:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .sidebar-container {
    display: none;
  }

  .dashboard-container {
    padding: 15px;
  }

  .btn-lg {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .sidebar-container {
    display: block;
  }
}

.sidebar-toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  z-index: 1000; /* Ensures it's on top of other elements */
}