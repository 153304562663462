.auth-form {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
  }
  
  .text-right {
    text-align: right;
  }
  
  .no-underline {
    text-decoration: none;
    color: #007bff;
  }
  
  .no-underline:hover {
    color: #0056b3;
  }
  
  .btn-blue {
    background-color: #007bff;
    border: none;
    color: white;
    width: 100%;
    padding: 10px 20px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
  }
  
  .btn-blue:hover {
    background-color: #0056b3;
  }
  
  .min-vh-100 {
    min-height: 100vh;
  }
  
  .d-flex.align-items-center.justify-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  button {
    display: inline-block;
    width: 100%;
    box-sizing: border-box;
  }
  