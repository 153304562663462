/* InviteSignUp.css */
.invite-signup-container {
    height: 100vh;
    background-color: #f0f8ff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .invite-signup-form {
    max-width: 450px;
    margin: auto;
    padding: 25px;
    border: 1px solid #ddd;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .invite-signup-form h2 {
    margin-bottom: 20px;
    font-weight: bold;
    color: #333;
  }
  
  .invite-signup-form p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 25px;
  }
  
  .invite-signup-form .inviter-name {
    font-weight: bold;
    color: #4A4A4A;
    text-transform: capitalize;
  }
  
  .invite-signup-form .btn-blue {
    background-color: #007bff;
    border: none;
    color: white;
    width: 100%;
    padding: 12px 20px;
    box-sizing: border-box;
    transition: background-color 0.3s ease;
    border-radius: 6px;
  }
  
  .invite-signup-form .btn-blue:hover {
    background-color: #0056b3;
  }
  
  .invite-signup-form .text-right {
    text-align: right;
  }
  
  .invite-signup-form .no-underline {
    text-decoration: none;
    color: #007bff;
  }
  
  .invite-signup-form .no-underline:hover {
    color: #0056b3;
  }
  
  .invite-signup-form .form-control {
    margin-top: 10px;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #ccc;
  }
  
  .invite-signup-form .text-center {
    color: #333;
  }

  .invite-signup-container .email-input {
    font-weight: bold;
}