.bank-integration-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f7fafc;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

.bank-integration-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h1 {
  color: #1a202c;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.description {
  color: #4a5568;
  margin-bottom: 1.5rem;
}

.connect-button {
  background-color: #5469d4;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.75rem 1.5rem;
  transition: background-color 0.2s ease;
  width: 100%;
}

.connect-button:hover {
  background-color: #4a5fc1;
}

.connect-button svg {
  margin-left: 0.5rem;
}

.processing {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 3px solid #e2e8f0;
  border-top: 3px solid #5469d4;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error {
  color: #e53e3e;
  margin-bottom: 1rem;
}

.retry-button {
  background-color: #e53e3e;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s ease;
}

.retry-button:hover {
  background-color: #c53030;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1rem;
  color: #4a5568;
}
  