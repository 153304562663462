.kyc-form-container {
    padding: 20px;
  }
  
  .kyc-form-container h2,
  .kyc-form-container p {
    margin-bottom: 20px;
  }
  
  .kyc-form-container h2 {
    margin-top: 50px; /* Adjust this value to start lower on the screen */
  }
  
  .auth-form {
    max-width: 600px;
    margin: auto;
  }
  
  .form-text {
    margin-top: 10px;
  }
  
  .bold-label {
    font-weight: bold;
    font-size: 1.1rem;
  }
  
  .btn-blue {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 10px 20px;
    transition: background-color 0.3s ease;
  }
  
  .btn-blue:hover {
    background-color: #0056b3;
  }
  